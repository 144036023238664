/* tslint:disable:indent */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {API_ENDPOINTS} from '../api/api-endpoints';
import {environment} from '../../../environments/environment';
import {AuthModel} from '../../modules/auth/_models/auth.model';
import {UserModel} from '../../modules/auth';

const API_USERS_URL = `${environment.apiUrl}/users`;

@Injectable({
	providedIn: 'root',
})
export class AuthHTTPService {
	constructor(private http: HttpClient) { }

	// public methods
	login(email: string, password: string): Observable<any> {
		const notFoundError = new Error('Not Found');
		if (!email || !password) {
			return of(notFoundError);
		}

		return this.http.post(
			API_ENDPOINTS.AUTH.LOGIN,
			{
				email: email.toLowerCase(),
				password: password
			}
		)
			.pipe(
				// map((response: ServerResponse) => {
				map((response: any) => {
					if (!response.error) {
						const auth = new AuthModel();
						auth.accessToken = response.message.sessionToken;
						auth.refreshToken = 'undisclosed';
						auth.expiresIn = new Date(Date.now() + 100 * 24 * 60 * 60 * 1000);
						return auth;
					} else {
						return notFoundError;
					}
				})
			);
	}

	createUser(user: UserModel): Observable<any> {
		user.roles = [2]; // Manager
		user.accessToken = 'access-token-' + Math.random();
		user.refreshToken = 'access-token-' + Math.random();
		user.expiresIn = new Date(Date.now() + 100 * 24 * 60 * 60 * 1000);
		user.pic = './assets/media/users/default.jpg';

		return this.http.post<UserModel>(API_USERS_URL, user);
	}

	forgotPassword(email: string): Observable<boolean> {
		return this.getAllUsers().pipe(
			map((result: UserModel[]) => {
				const user = result.find(
					(u) => u.email.toLowerCase() === email.toLowerCase()
				);
				return user !== undefined;
			})
		);
	}

	getUserByToken(token: string): Observable<UserModel> {
		return this.http.post(
			API_ENDPOINTS.AUTH.GET_USER_DETAILS,
			{}
		)
			.pipe(
				map((response: any) => {
					if (!response.error) {
						const user = new UserModel();
						user.setUser({
							id: -1,
							email: response.message.email,
							roles: [response.message.userRole],
							firstname: response.message.firstName,
							lastname: response.message.lastName,
							fullname: response.message.firstName + ' ' + response.message.lastName
						});
						return user;
					} else {
						return undefined;
					}
				})
			);
	}

	getAllUsers(): Observable<UserModel[]> {
		return this.http.get<UserModel[]>(API_USERS_URL);
	}
}
