/* tslint:disable:indent */
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {AuthModel} from '../modules/auth/_models/auth.model';

@Injectable()
export class StreamupRequestInterceptor implements HttpInterceptor {

	private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
	private getAuthFromLocalStorage(): AuthModel {
		try {
			const authData = JSON.parse(
				localStorage.getItem(this.authLocalStorageToken)
			);
			return authData;
		} catch (error) {
			console.error(error);
			return undefined;
		}
	}

	intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const Authorization = this.getAuthFromLocalStorage() ? this.getAuthFromLocalStorage().accessToken : 'None';
		return next.handle(httpRequest.clone({ setHeaders: { Authorization } }));
	}

}
