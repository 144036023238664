export const SERVER = {BASE: 'https://api.dedicateware.com/api/v1/'};

export const API_ENDPOINTS = {
  AUTH : {
    LOGIN: SERVER.BASE + 'auth/login',
    GET_USER_DETAILS: SERVER.BASE + 'auth/getUserDetails'
  },
  WCC: {
    WCC_GET_WEBSITES: SERVER.BASE + 'wcc/getWebsites',
    WCC_REMOVE_WEBSITE: SERVER.BASE + 'wcc/removeWebsite',
    WCC_UPDATE_WEBSITE: SERVER.BASE + 'wcc/updateWebsite',
    WCC_GET_PROMOTIONS: SERVER.BASE + 'wcc/getPromotions',
    WCC_UPDATE_PROMOTION: SERVER.BASE + 'wcc/updatePromotion',
    WCC_REMOVE_PROMOTION: SERVER.BASE + 'wcc/removePromotion',
    WCC_UPDATE_SITE_LINKED_PROMOTION: SERVER.BASE + 'wcc/updateWebsiteLinkedPromotion'
  },
  WHMCS : {
    WHMCS_GET_CLIENTS: SERVER.BASE + 'whmcs/getWhmcsClients',
    WHMCS_SYNC_CLIENTS: SERVER.BASE + 'whmcs/syncWhmcsClients',
    WHMCS_GET_CLIENT_DETAILS: SERVER.BASE + 'whmcs/getWhmcsClientDetails',
    WHMCS_GET_STATS: SERVER.BASE + 'whmcs/getWhmcsStats'
  },
  MONITORING : {
    GET_SERVER_UPTIME: SERVER.BASE + 'monitoring/getServerUptime'
  },
  SMS: {
    SMS_SEND_NEW: SERVER.BASE + 'sms/send',
    SMS_GET_ACCOUNT_BALANCE: SERVER.BASE + 'sms/getAccountBalance',
    SMS_GET_ACCOUNT_HISTORY: SERVER.BASE + 'sms/getAccountHistory',
    SMS_GET_PREDEFINED: SERVER.BASE + 'sms/getPredefined'
  },
  TWILIO: {
    GET_ACCOUNT_BALANCE: SERVER.BASE + 'twilio/getAccountBalance'
  },
  SERVERS: {
    VULTR_GET_INSTANCES: SERVER.BASE + 'vultr/getInstances',
    VULTR_GET_SNAPSHOTS: SERVER.BASE + 'vultr/getSnapshots',
    VULTR_CREATE_SNAPSHOT: SERVER.BASE + 'vultr/createSnapshot'
  },
  FINANCIAL: {
    STRIPE_PAYOUTS: SERVER.BASE + 'stripe/getPendingPayouts'
  },
  HTML5PLAYERS: {
    GET_STREAM_STATS: SERVER.BASE + 'playerStats/getRecords',
    UPDATE_STREAM_STATUS: SERVER.BASE + 'playerStats/updateStreamStatus'
  }
};
